.project-wrapper {
    top: 70px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-card-container {
    width: 60vw;
    position: inherit;
    margin: auto;
    max-width: var(--maximum-allowable-screen-width);
}

#projects-spacer {
    height: 90px;
}

.project-filters {
    height: auto;
    width: 260px;

    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate(0, 62px); /* ??? */

    padding: 20px;
    border-radius: 0px 8px 8px 0px;
	box-shadow: 0 15px 15px 1px rgba(100, 100, 100, 0.4);
    background-color: white;
}

.project-filters > form {
    width: 100%;
}

.project-filters > form > h4 {
    width: 100%;
    margin-top: 5px;
}

.project-filters hr {
    margin-bottom: 5px;
}

.project-filters label, .project-filters input  {
    cursor: pointer;
}

.project-filters label:hover, .project-filters input:hover  {
    font-weight: var(--font-bold);
}

.project-filters label {
    width: 100%;
}

/* ---------------------------------------------------------------- */
/* ----------------- Other Screen Resolutions --------------------- */
/* ---------------------------------------------------------------- */

@media only screen and (max-width: 1050px) {
    .project-filters {
        display: none;
    }
}

@media only screen and (max-width: 960px) {
    .project-card-container {
        width: 90vw;
    }
}