.gradient-back-art {
    width: 100vw;
    height: 600px;
    background: linear-gradient(-45deg,#23A6D5, #23D5AB);
    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* ---------------------------------------------------------------- */
/* ---------------------- "CREATE!" Banner ------------------------ */
/* ---------------------------------------------------------------- */

.reg-banner {
    color: white;
}
.art-banner {
    color: #fdef73;
}

.reg-banner, .art-banner {
    font: 17em/1 Montserrat, Impact;
    position: absolute;
    display: flex;
    justify-content: center;
    transition: all 300ms ease-in-out;
}

.btn-container {
    width: 100vw;
    height: 600px;
    position: absolute;
}

.btn-create {
    width: 35px;
    height: 20px;
    margin: 10px;
    /* position: relative; */
    float: right;
    position: absolute;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 15px;
    z-index: 2;
    transition: all 300ms ease-in-out;
}

.btn-create > .btn-circle {
    margin-top: 2.2px;
    margin-left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: all 300ms ease-in-out;
}

.btn-create:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
    transition: ease-in-out 2ms;
}

.btn-create.active {
    background: #144a5f;
}

.btn-create.active:hover {
    cursor: pointer;
    background-color: #408ba8;
    transition: ease-in-out 2ms;
}

.btn-create.active > .btn-circle {
    margin-left: 17px;
}

.unseen {
    display: none;
}

/* ---------------------------------------------------------------- */
/* ----------------- Other Screen Resolutions --------------------- */
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
    .reg-banner, .art-banner {
        font: 12em/1 Montserrat, Impact;
    }
}

@media only screen and (max-width: 900px) {
    .reg-banner, .art-banner {
        font: 8.5em/1 Montserrat, Impact;
    }
}

@media only screen and (max-width: 600px) {
    .gradient-back-art {
        height: var(--reduced-banner-height);
    }
    .btn-container {
        height: var(--reduced-banner-height);
    }
    
    .reg-banner, .art-banner {
        font: 4.5em/1 Montserrat, Impact;
    }
}