.contact-wrapper {
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    height: auto;
    width: 100vw;
}

.background-contact {
    width: 100vw;
    height: 100vh;
    z-index: -1;

    background: url("../assets/images/projects/cover_img.jpg") no-repeat center center / cover;
    /* background-position-y: 0px; */

    background-position: 55% 0%;
    text-shadow: 0 0 5px white;


    display: flex;
    justify-content: center;
    align-items: center;

}

ul#contact-icons {
    z-index: 1;

    width: auto;
    height: auto;
    z-index: 0;

    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 17px;
    color: white;

    /* position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 0; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

ul#contact-icons li i {
    color: black;
}

/* ul#contact-icons li:hover, ul#contact-icons li:hover i { */
#ln:hover i, #tw:hover i, #ig:hover, #gt:hover i, #em:hover i {
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

#ln:hover i {
    color: #2867B2;
}
#tw:hover i {
    color: #1DA1F2
}
#ig:hover i {
    color: #C13584;
}
#gt:hover i {
    color: #6CC644;
}
#em:hover i {
    color: coral;
}

ul#contact-icons {
    list-style: none;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
}

ul#contact-icons li {
    padding: 15px 0px;
}

@media only screen and (max-width: 900px) {
    ul#contact-icons {
        /* padding: 0 55px; */
    }
}

@media only screen and (max-width: 600px) {
    ul#contact-icons {
        /* padding: 0 25px; */
    }
}