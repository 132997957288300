.nav-wrapper {
    width: 100vw;
    height: 70px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 10%;
    background: rgba(0, 0, 0, 0.3);
}

.nav-container {
    position: relative;
    z-index: 1;
}

.background {
    width: 100%;
    height: 100%;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    background-size: 400%, 400%;
    z-index: 0;
}

.logo-wrapper {
    color: #edf0f1;
    cursor: pointer;
    margin-right: auto;
}

.logo-wrapper-inner {
    display: inline-flex;
    align-items: center;
}

.logo {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.logo-text {
    padding-inline-start: 10px;
}

.drop-tog {
    width: 40px;
    height: 40px;
    border: none;
    background: none;
}

.inactive-tog, .active-tog, .drop-down {
    display: none;
    z-index: 1;
}

.drop-tog {
    position: absolute;
}

.nav-links {
    list-style: none;
}

.nav-links li {
    display: inline-block;
    padding: 0px 20px;
    transition: all 0.3s ease 0s;
    z-index: 1;
}

.nav-links li.active a {
    color: #fdef73;
}

.nav-links li a:hover {
    cursor: pointer;
    color: #fdef73;
    transition: all 0.3s ease 0s;
}

.nav-btn {
    margin-left: 20px;
    padding: 9px 25px;
    border-radius: 50px;
    transition: all 0.3s ease 0s;
}

.native-nav-btn {
    color: #000000;
    background-color: #fdf06e;
    border: none;
}

.native-nav-btn:hover {
    color: #fff;
    cursor: pointer;
    background-color: #d6ca45;
    transition: 0.3s ease-in-out;
}

.drop-tog, span.nav-background {
    display: none;
}

/* ---------------------------------------------------------------- */
/* ----------------------- Other Page Nav ------------------------- */
/* ---------------------------------------------------------------- */
.other-nav-btn {
    border: 2px solid#89CFF0;
    background-color: #051f34;
}

.other-nav-btn:hover {
    color: #fff;
    cursor: pointer;
    background-color: #051f34;
    transition: 0.3s ease-in-out;
}

.heading-other {
    width: 100%;
    height: 70px;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 10%;
    background: #051f34;
    border-bottom: 2px solid #89CFF0;
    z-index: 1;
    color: #89CFF0;
}

.heading-other * {
    color: #89CFF0 !important;
}

.heading-other p, 
.heading-other .nav-links *,
.heading-other .cta *,
.heading-other .logo-wrapper-inner {
    color: #89CFF0 !important;
    font-family: 'Share Tech Mono', sans-serif;
}

.heading-other p {
    padding-right: 30px;
}

/* ---------------------------------------------------------------- */
/* ------------------ Photography Page Nav ------------------------ */
/* ---------------------------------------------------------------- */
.photography-nav-btn {
    background-color: #F0F7E0;
    border: 2px solid #95BA61;
}

.photography-nav-btn:hover {
    cursor: pointer;
    background-color: #d7e0c3;
    transition: 0.3s ease-in-out;
}

.heading-photography {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 10%;
    background-color: #F0F7E0;
}

.heading-photography * {
    color: #95BA61 !important;
}

/* ---------------------------------------------------------------- */
/* ------------------------- Home Page Nav ------------------------ */
/* ---------------------------------------------------------------- */
.home-nav-btn {
    color: #4887b1;
    cursor: pointer;
    background-color: #84D2F6;
    transition: 0.3s ease-in-out;
}

.home-nav-btn:hover {
    color: #84D2F6;
    background-color: #4887b1;
}

/* ---------------------------------------------------------------- */
/* ------------------- Other Screen Resolutions ------------------- */
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 960px) {
    .nav-btn, .nav-container-closed nav {
        display: none;
    }

    .nav-container-open {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.8);
    }

    span.nav-background {
        display: block;
        position: fixed;
        left: 0;
        top: 70px;
        width: 100vw;
        height: calc(100vh - 70px);
        background-color: rgba(0, 0, 0, 0.8);
    }

    .nav-container-open nav {
        position: fixed;
        left: 0;
        top: 70px;
        /* height: 100%; */
        /* background-color: #000000; */
        width: 100vw;
        height: calc(50vh - 70px);
        margin: auto;
    }

    .nav-container-open nav ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .nav-container-open nav ul li {
        padding: 25px 0px;
    }

    .drop-tog {
        display: block;
        position: absolute;
    }

    .drop-tog:hover {
        cursor: pointer;
    }
}