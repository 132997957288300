.artwork-wrapper {
    top: 0;
    left: 0;
    height: auto;
    width: 100vw;
    position: absolute;
    z-index: -1;
}

.section-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section {
    width: 100%;
    padding: 0 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

/* ---------------------------------------------------------------- */
/* ---------------------- Section 1: Panels ----------------------- */
/* ---------------------------------------------------------------- */

.section1 {
    height: auto;
    align-items: center;
    max-width: var(--maximum-allowable-screen-width-component); 
}

.s1-text {
    padding: 10px 10px;
    justify-self: flex-start;
}

.slide-container {
    display: flex;
    width: 100%;
}

.panel {
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    border-radius: var(--border-radius-px);
    color: #fff;
    cursor: pointer;
    flex: 0.5;
    margin: 10px;
    transition: flex 0.7s ease-in;
}

.panel h3 {
    font-size: 24px;
    position: absolute;
    opacity: 0;
    padding-left: 20px;
    padding-top: 20px;
}

.panel:hover {
    box-shadow: 0 0 0 4pt rgba(255,168,210,255);
    transition: ease-in-out 0.3s;
}

.panel.active {
    flex: 5;
}

.panel.active h3{
    opacity: 1;
    transition: opacity 0.3s ease-in 0.4s;
}

/* ---------------------------------------------------------------- */
/* -------------------------- Section 2 --------------------------- */
/* ---------------------------------------------------------------- */

.section2 {
    padding-bottom: 20px;
    max-width: var(--maximum-allowable-screen-width-component);
}

.s2-text {
    display: flex;
    color: black;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.s2-text p a {
    color: rgb(21, 100, 173);
    text-decoration: underline;
}

.s2-text i {
    color: rgb(21, 100, 173);
    text-decoration: underline;
}

/* ---------------------------------------------------------------- */
/* ----------------- Section 3: Instagram Posts ------------------- */
/* ---------------------------------------------------------------- */

.section3 {
    height: auto;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
}

.s3-container {
    width: 100%;
    padding: 0 30px;
    max-width: var(--maximum-allowable-screen-width-component); 
}

.media-container {
    position: relative;
    height: 100%;
    z-index: 1;
}

.post {
    width: auto;
    height: auto;
    z-index: 0;
}

.post .media-container img {
    width: 100%;
    height: 70vh;
    max-height: 750px;
    border-radius: var(--border-radius-px);
    object-fit: cover;
}

.post .media-container i {
    width: 100%;
    height: 100%;
    font-size: 3rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s;
}
.post .media-container i#expand-icon {
    z-index: 2;
}

.post .media-container:hover i {
    cursor: pointer;
    opacity: 1;
    transition: ease-in-out 0.2s;
}

.modal {
    width: 100%;
    height: 100%;
}

.modal-cover {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    z-index: 1;
}

.modal-cover #timestamp {
    font-size: 1.3em;
    padding-top: 4.5em;
    z-index: 1;
}

.instagram-posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 20px;
}

.img-placeholder {
    height: 70vh;
    background-color: #eee;
    border-radius: var(--border-radius-px);

    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulse 1s infinite ease-in-out;
    -webkit-animation: pulse 1s infinite ease-in-out;
}

.img-placeholder:hover {
    background-color: rgb(150, 150, 150);
    transition: ease-in-out 0.2s;
    cursor: pointer;
    animation: none;
    -webkit-animation: none;
}

.img-placeholder:hover #spinner-icon {
    color: white;
    transition: ease-in-out 0.2s;
}

#spinner-icon {
    font-family: sans-serif;
    display: block;
    color: black;
}

/* ---------------------------------------------------------------- */
/* --------------------------- Modals ----------------------------- */
/* ---------------------------------------------------------------- */

.image-pop-up, .video-pop-up, .carousel-pop-up {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.25s ease-out;
    
    z-index: 15 !important;

}

.image-pop-up.open, .video-pop-up.open, .carousel-pop-up.open {
    opacity: 15;
    pointer-events: all;
    z-index: 15 !important;
}

.carousel-pop-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.full-img, .full-vid {
    position: absolute;
    object-fit: cover;
    max-width: 95vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    transition: all 0.25s ease-out;
    z-index: 15;
}

.full-car {
    object-fit: cover;
    max-width: 95vw;
    transition: all 0.25s ease-out;
    z-index: 1;
}

.full-img.open, .full-vid.open {
    max-width: 85vw;
    max-height: 85vh;
    transform: translate(-50%, -50%) scale(1);
}

.full-car.open  {
    max-width: 80vw;
    max-height: 80vh;
}

.car-btn {
    border: none;
    width: 50px;
    height: 50px;
    color: white;
    background-color: rgba(100, 100, 100, 0.4);
    z-index: 17;
}

.car-left-btn {
    border-radius: 5px 0 0 5px;
}

.car-right-btn {
    border-radius: 0 5px 5px 0;
}

.car-left-btn:hover, .car-right-btn:hover  {
    cursor: pointer;
    background-color: rgba(50, 50, 50, 0.2);
    transition: ease-in-out 0.2s;
}

.disabled-btn {
    color: rgba(0, 0, 0, 0.4);
}

.disabled-btn:hover {
    cursor: not-allowed;
}

.car-navigator {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    /* position: absolute; */
    margin-top: 1rem;
    width: 10rem;
    z-index: 16;
}

/* ---------------------------------------------------------------- */
/* ---------------------------- Footer ---------------------------- */
/* ---------------------------------------------------------------- */


.ft {
    width: 100%;
    height: 70px;
    font-size: 24px;
    color: white;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-header {
    margin-bottom: 15px;
}

/* ---------------------------------------------------------------- */
/* ------------------------- Animations --------------------------- */
/* ---------------------------------------------------------------- */

@keyframes pulse {
    0% {
        background-color: rgb(200, 200, 200, 1);
    }
    50% {
        background-color: rgba(200, 200, 200, 0.8);
    }
    100% {
        background-color: rgba(200, 200, 200, 1);
    }
}

@-webkit-keyframes  pulse {
    0% {
        background-color: rgb(200, 200, 200, 1);
    }
    50% {
        background-color: rgba(200, 200, 200, 0.8);
    }
    100% {
        background-color: rgba(200, 200, 200, 1);
    }
}

/* ---------------------------------------------------------------- */
/* ----------------- Other Screen Resolutions --------------------- */
/* ---------------------------------------------------------------- */

@media only screen and (max-width: 1400px) {
    .section1 {
        max-width: 100%;
    }
    
    .s3-container {
        width: 100%;
        max-width: 100%; 
    }
}

@media(max-width: 900px) {
    .panel:nth-of-type(3), .panel:nth-of-type(2) {
        display: none;
    }

    .s2-text {
        display: inline;
        text-align: center;
    }

    .s3-container {
        padding: 0 0;
    }

    .instagram-posts {
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 600px) {
    .instagram-posts {
        grid-template-columns: 1fr;
    }
}