:root {
    --maximum-allowable-screen-width: 1200px;
    --maximum-allowable-screen-width-larger: 1400px;
    --maximum-allowable-screen-width-largest: 1600px;
    --maximum-allowable-screen-width-component: 1900px;

    --media-screen-tablet: 960px;
    --media-screen-phone: 640px;

    --reduced-banner-height: 350px;
    --banner-image-height: 600px;

    --home-page-theme-color: #84D2F6;
    --home-page-theme-color-hover: #4887b1;
    --home-page-text-theme-color: #4887b1;
    --home-page-text-theme-color-hover: #84D2F6;

    --flower-yellow: #fdf06e;
    --flower-pink: #fea9d2;
    --flower-pink-text: #c26491;
    --six-red: #c3515c;

    --border-radius-px: 5px;
    --border-radius-px-rounder: 7px;

    --font-bold: 625;
    --font-bolder: 700;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("assets/fonts/Montserrat/Montserrat-VariableFont_wght.woff2") format('woff2-variations');
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url("assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.woff2") format('woff2-variations');
}

@font-face {
    font-family: "Share Tech Mono";
    font-style: normal;
    font-display: swap;
    src: url("assets/fonts/Share_Tech_Mono/ShareTechMono-Regular.woff2") format('woff2-variations');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    width: 100%;
    height: 100%;
}

body {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-weight: 550;
    font-size: 17px;

    width: 100vw;
    height: auto;

    overflow-x: hidden;
}

.app-content {
    width: 100%;
    height: auto;

    top: 0;
    left: 0;
    position: absolute;
}

li, a, button {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    font-weight: var(--font-bold);
    font-size: 16px;
    color: #edf0f1;
    text-decoration: none;
}

span.clickable-span {
    -webkit-filter: brightness(100%);
}

span.clickable-span:hover {
    cursor: pointer;
    -webkit-filter: brightness(80%);
}
  