.tictactoe-wrapper {
    width: auto;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-content: center;

    margin: 40px 0px;
    padding: 20px;
    border: solid 1px black;
    border-radius: 4px;
}

.tictactoe-game, .tictactoe-text {
    width: 48%;
    height: auto;
    max-width: 48%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: auto; */
}

.tictactoe-grid {
    max-width: 250px;
    max-height: 250px;
    padding: 20px;
    border: solid 1px black;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
}

.tictactoe-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
}

.tictactoe-cell-btn {
    margin: 5px;
    padding: 10px;
    width: 50px;
    height: 50px;
    
    color: black;
    cursor: pointer;
}

.tictactoe-cell-btn:disabled,
.tictactoe-cell-btn[disabled]{
    cursor: not-allowed;
}

.tictactoe-text {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100;
}

.tictactoe-gamestats {
    margin-bottom: 10px;
}

.tictactoe-text hr {
    width: 50%;
    margin-bottom: 10px;
}

.tictactoe-text h4 {
    padding: 5px;
    border-radius: 2px;
    margin-bottom: 5px;
}

#tictactoe-win {
    color: white;
    background-color: rgb(45, 173, 75);
}

#tictactoe-loss {
    color: white;
    background-color: rgb(196, 30, 41);
}

.tictactoe-reset-btn {
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(45, 173, 75);
    color: white;
    border: solid 1px black;
}

.tictactoe-reset-btn:hover {
    background-color: rgb(35, 136, 59);
    transition: ease-in 0.1s;
}

@media only screen and (max-width: 1200px) {
    .tictactoe-wrapper  {
        flex-direction: column;
    }

    .tictactoe-game, .tictactoe-text {
        width: 100%;
        max-width: 100%;
    }

    .tictactoe-text {
        margin-top: 15px;
    }
}