.blog-wrapper {
    top: 70px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-content {
    width: 60vw;
    max-width: var(--maximum-allowable-screen-width);
}

.blog-post {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* ---------------------------------------------------------------- */
/* ----------------------- Vocabulary Table ----------------------- */
/* ---------------------------------------------------------------- */
.vocabulary-container {
    margin: 20px 0px;
    width: 100%;

    padding: 10px;
    background-color: #eee;
    border-radius: var(--border-radius-px-rounder);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-height: 40vh;
}

.vocabulary-header {
    border-radius: var(--border-radius-px);
    transition: 0.1s ease-in-out;
    width: 58%;
}

.vocabulary-header:hover {
    cursor: pointer;
    background-color: #b4b4b4;
}

.vocabulary-search-bar, .vocabulary-search-bar span {
    display: flex;
    align-items: center;
}

.vocabulary-search-bar {
    width: 100%;
    justify-content: space-between;
    padding: 10px 0px;

    z-index: 1;
    background-color: #eee;
}

.vocabulary-search-bar span * {
    padding: 0px 5px;
}

input.vocabulary-search-box {
    padding: 10px;
    width: 40%;
}

.vocabulary-table-container {
    max-height: 100%;
    overflow-y: auto; 
    width: 100%;

    display: inline-block;
    justify-content: center;
    align-items: center;
}

table.vocabulary-table {
    width: 100%;
    z-index: 0;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;

    font-size: medium;
}

.vocabulary-table tr {
    border-bottom: 1px solid black;
}

.vocabulary-table thead tr th:nth-child(1), .vocabulary-table tr td:nth-child(1) {
    border-right: 1px solid black;
}

.vocabulary-table tr.vocabulary-table-row:nth-child(odd) {
    background-color: white;
}

.vocabulary-table tr.vocabulary-table-row:nth-child(even) {
    background-color: #D6EEEE;
}

.vocabulary-table tr.vocabulary-table-row:hover {
    background-color: #eee;

}

.vocabulary-table th {
    position: sticky; 
    top: 0px; 
    z-index: 1;
    padding: 10px;
    border-top: 1px solid black;
    background-color: white;
}

table.vocabulary-table tr th,
table.vocabulary-table tr td {
    border-right: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    padding: 10px;
}
  
table.vocabulary-table tr th:first-child,
table.vocabulary-table tr td:first-child {
    border-left: 1px solid black;
}

table.vocabulary-table tr:first-child th:first-child {
    border-top-left-radius: var(--border-radius-px);
}
table.vocabulary-table tr:first-child th:last-child {
    border-top-right-radius: var(--border-radius-px);
}
table.vocabulary-table tr:last-child td:first-child {
    border-bottom-left-radius: var(--border-radius-px);
}
table.vocabulary-table tr:last-child td:last-child {
    border-bottom-right-radius: var(--border-radius-px);
}




@media only screen and (max-width: 960px) {
    .vocabulary-header {
        width: 48%;
    }

    input.vocabulary-search-box {
        width: 50%;
    }
}

@media only screen and (max-width: 640px) {
    .vocabulary-container {
        max-height: 50vh;
    }

    .vocabulary-search-bar {
        flex-direction: column;
    }

    .vocabulary-header {
        width: 90%;
        justify-content: left;
        margin-bottom: 10px;
    }

    input.vocabulary-search-box {
        width: 90%;
    }
}

/* ---------------------------------------------------------------- */
/* -------------------------- Blog Card --------------------------- */
/* ---------------------------------------------------------------- */
.card-container-blog {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 15px 0px;
    padding: 15px;
    border: 1px solid black;
    border-radius: var(--border-radius-px);
    background-color: #eee;
}

.card-container-blog:hover {
    cursor: pointer;
    background-color: #bebebe;
    color: white;
    transition: ease-in-out 0.2s;
}

.card-container-blog hr {
    width: 100%;
    border: 1px solid rgb(100, 100, 100);
}

.blog-card-segment {
    font-size: smaller;
}

.blog-card-segment div {
    margin: 10px 0px;
}

.blog-card-segment h4 {
    margin: 5px 0px;
}

.blog-card-segment span {
    display: inline-block;
}

.blog-card-container {
    width: 100%;
    margin: 20px 0px;
}



/* ---------------------------------------------------------------- */
/* ----------------- Other Screen Resolutions --------------------- */
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 640px) {
    .blog-content {
        width: 90vw;
    }
}
