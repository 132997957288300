/* @import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap'); */

.other-container {
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;

    font-family: 'Share Tech Mono', monospace;
    overscroll-behavior: none;
    background-color: #051f34;
    color: #89CFF0;
    overflow-x: hidden;
    min-height: 100vh;
    cursor: url("../assets/images/other/cursor.png"), auto;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.severance-package {
    width:100%;
    height:auto;
    display:flex;
    justify-content:center;
    align-items:center;
}

.header-percent {
    color: transparent;
    -webkit-text-stroke: 1px #8EE3F1;
}

.numbers {
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-auto-rows: 80px;
    margin-top: 70px;
    z-index: 1;
}

.numbers>div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    max-width: 100px;
    max-height: 100px;
}

@media only screen and (min-width: 1024px) {
    .numbers {
        grid-template-columns: repeat(18, 1fr);
    }
    .header-percent {
        padding-left: 1rem;
    }
}

@media only screen and (min-width: 1200px) {
    .numbers {
        grid-template-columns: repeat(12, 1fr);
    }

    .numbers>div.animation-1, .numbers>div.animation-4 {
        animation: none;
        -webkit-animation: none;
    }
    .header-percent {
        padding-left: 5px;
    }
}

@media only screen and (max-width: 1024px) {
    .numbers {
        grid-template-columns: repeat(8, 1fr);
    }
    .header-percent {
        padding-left: 5px;
    }
}
@media only screen and (max-width: 1200px) {
    .numbers {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media only screen and (max-width: 720px) {
    .header-percent {
        display: none;
    }
    .other-footer .code, .other-footer .note {
        padding: 0px 10px;
    }
}

.numbers>div.kanim {
    animation: pulse 2s ease-in-out;
    -webkit-animation: pulse 2s ease-in-out;
}

.numbers>div.animation-1 {
    animation: float-bottom 5s ease-in-out infinite;
    -webkit-animation: float-bottom 5s ease-in-out infinite;
}

.numbers>div.animation-2 {
    animation: float-left 5s ease-in-out infinite;
    -webkit-animation: float-left 5s ease-in-out infinite;
}

.numbers>div.animation-3 {
    animation: float-right 5s ease-in-out infinite;
    -webkit-animation: float-right 5s ease-in-out infinite;
}

.numbers>div.animation-4 {
    animation: float-top 5s ease-in-out infinite;
    -webkit-animation: float-top 5s ease-in-out infinite;
}

.numbers>div.delay-1 {
    animation-delay: 1s;
}

.numbers>divv.delay-2 {
    animation-delay: 1.5s;
}

.numbers>divv.delay-3 {
    animation-delay: 2s;
}

.numbers>div.duration-2 {
    animation-duration: 2s;
}

.numbers>div.duration-2 {
    animation-duration: 3s;
}

.numbers>div.duration-3 {
    animation-duration: 4s;
}

.other-footer {
    border-top: 2px solid #8EE3F1;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #08203A;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 8px;
    flex-wrap: wrap;
}

.other-footer>div {
    width: 100%;
}

.other-footer .note {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    padding-top: 8px;
    font-size: 16px;
    border-top: 1px solid #8EE3F1;
}

.other-footer .code {
    display: flex;
    justify-content: center;
    align-items: center;
}

.other-footer #iar {
    text-decoration: underline;
    font-family: 'Share Tech Mono', monospace;
    color: #89CFF0;
}

@keyframes float-bottom {
    0% {
        padding-bottom: 0;
        padding-top: 0;
    }

    50% {
        padding-bottom: 10px;
        padding-top: 0;
    }

    100% {
        padding-bottom: 0;
        padding-top: 0;
    }
}

@keyframes float-top {
    0% {
        padding-bottom: 0;
        padding-top: 0;
    }

    50% {
        padding-bottom: 0;
        padding-top: 10px;
    }

    100% {
        padding-bottom: 0;
        padding-top: 0;
    }
}

@keyframes float-left {
    0% {
        padding-left: 0;
        padding-right: 0;
    }

    50% {
        padding-left: 10px;
        padding-right: 0;
    }

    100% {
        padding-left: 0;
        padding-right: 0;
    }
}

@keyframes float-right {
    0% {
        padding-left: 0;
        padding-right: 0;
    }

    50% {
        padding-left: 0;
        padding-right: 10px;
    }

    100% {
        padding-left: 0;
        padding-right: 0;
    }
}

@keyframes pulse {
    0% {
        color: rgba(137, 207, 240, 1);
    }

    50% {
        color: rgba(137, 207, 240, 0.6);
    }

    100% {
        color: rgba(137, 207, 240, 1);
    }
}


@-webkit-keyframes float-bottom {
    0% {
        padding-bottom: 0;
        padding-top: 0;
    }

    50% {
        padding-bottom: 10px;
        padding-top: 0;
    }

    100% {
        padding-bottom: 0;
        padding-top: 0;
    }
}

@-webkit-keyframes float-top {
    0% {
        padding-bottom: 0;
        padding-top: 0;
    }

    50% {
        padding-bottom: 0;
        padding-top: 10px;
    }

    100% {
        padding-bottom: 0;
        padding-top: 0;
    }
}

@-webkit-keyframes float-left {
    0% {
        padding-left: 0;
        padding-right: 0;
    }

    50% {
        padding-left: 10px;
        padding-right: 0;
    }

    100% {
        padding-left: 0;
        padding-right: 0;
    }
}

@-webkit-keyframes float-right {
    0% {
        padding-left: 0;
        padding-right: 0;
    }

    50% {
        padding-left: 0;
        padding-right: 10px;
    }

    100% {
        padding-left: 0;
        padding-right: 0;
    }
}

@-webkit-keyframes pulse {
    0% {
        scale: 1.0;
    }

    50% {
        scale: 1.5;
    }

    100% {
        scale: 1.0;
    }
}