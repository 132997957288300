.home-wrapper {
    width: 100vw;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -2;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-container {
    width: 100%;
    /* max-width: var(--maximum-allowable-screen-width-larger); */
    height: auto;
    min-height: 100vh;
    z-index: -1;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.home-background {
    top: 0px;
    width: 100vw;
    height: 100vh;
    /* max-width: 2500px; */
    position: absolute;
    z-index: -10;
    overflow: hidden;
    background: linear-gradient(
        -45deg,
        #c4c9d7,
        #eef0f6
        /* rgb(225 229 240) #e1e5f0 */
    );

}

img#home-background-img {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: absolute;
    object-fit: cover;
    object-position: 70% 0;

    top: 0px;
    left: 15vw;
    width: 100%;
    height: 100%;

    -webkit-mask-image: linear-gradient(
        90deg,
        rgb(0 0 0 / 0),
        rgb(0 0 0 / 0.5), 
        rgb(0 0 0 / 1) 25%
    );
    mask-image: linear-gradient(
        90deg,
        rgb(0 0 0 / 0),
        rgb(0 0 0 / 0.5), 
        rgb(0 0 0 / 1) 25%
    );
}

div.classic-hero-container {
    width: 32vw;
    position: absolute;
    top: 16vh;
    left: 0;
    margin-left: 10vw;
}

p#classic-hero-container-description {
    width: 500px;
    overflow: hidden;
    font-size: 1vw;

    color: var(--home-page-text-theme-color);
    margin: 20px 0;
}

button#classic-hero-container-btn {
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    font-size: 1vw;

    /* border: solid 1px var(--home-page-text-theme-color); */
    /* border-radius: var(--border-radius-px); */

    color: var(--home-page-text-theme-color);
    background-color: var(--home-page-theme-color);
}

button#classic-hero-container-btn:hover {
    color: var(--home-page-text-theme-color-hover);
    background-color: var(--home-page-theme-color-hover);
    transition: ease-in-out 0.25s;
}

hr#classic-hero-container-divider {
    width: 90%;
    margin-top: 25px;
    /* margin-bottom: 15px; */
    border: 1px solid var(--home-page-text-theme-color);
    /* background-color: var(--home-page-theme-color); */
}

/* ---------------------------------------------------------------- */
/* ---------------------------- Type ------------------------------ */
/* ---------------------------------------------------------------- */

.type-container {
    text-align: left;
    max-width: 100%;
    font-size: 4vw;
    font-weight: normal;
}

.type-container span:nth-of-type(1) {
    font-weight: normal;
    color: var(--home-page-text-theme-color);
}

.type-container span:nth-of-type(2) {
    color: #c3515c;
}

.type-cursor {
    position: relative;
    height: 1em;
    border-right: 0.2rem solid #c3515c;
    animation: blink 0.75s infinite;
}

.mini-project-cards {
    width: auto;
    position: inherit;
    display: flex;

}

.projects-preview {
    display: grid;
    justify-content:center;
    grid-template-rows: auto;
    grid-template-areas: 
    ". .";
}

span#view-all-projects {
    padding: 20px;
    color: white; 
    font-weight: var(--font-bold); 
    font-size: 1.5em;
    margin: auto;
    background: linear-gradient(-45deg, rgb(89, 65, 87), rgb(125, 140, 196));
    border-radius: var(--border-radius-px-rounder);
}

/* ---------------------------------------------------------------- */
/* --------------------------- Badges ----------------------------- */
/* ---------------------------------------------------------------- */
#classic-hero-container-badges {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.info-badge {
    max-width: 13vw;
    margin-top: 50px;
}

.info-badge:nth-of-type(1), .info-badge:nth-of-type(3) {
    margin-right: 3vw;
} 

div.info-badge-header {
    display: flex;
    color: #2c668d;
    margin-bottom: 10px;
}

div.info-badge-header > span {
    margin: auto 10px;
    font-size: 0.8vw;
}

div.info-badge-header span.info-badge-title {
    display: flex;
    flex-direction: column;
}

div.info-badge span.info-badge-text p, div.info-badge span.info-badge-text p * {
    font-size: 0.8vw;
    color: var(--home-page-text-theme-color);
    overflow: hidden;
}

div.info-badge span.info-badge-text p caption {
    display: flex;
    text-decoration: underline;
    color: #2c668d;
    cursor: pointer;
}

div.info-badge span.info-badge-text p a {
    display: inline-block;
}

div.info-badge span.info-badge-text p caption:hover, div.info-badge span.info-badge-text p caption a:hover {
    color: #164565;
    transition: ease-in-out 0.2s;
    font-weight: var(--font-bold);
}

/* ---------------------------------------------------------------- */
/* --------------------- Media Containers ------------------------- */
/* ---------------------------------------------------------------- */

.media-card-container {
    color: white;
    width: 60%;
    height: auto;
    min-height: 60%;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    overflow: hidden;


    backdrop-filter: blur(15px);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.media-card-container:hover {
    box-shadow: 0 0 0 4pt rgba(0, 0, 0, 0.301);
    transition: .1s;
}

.media-card-container .media-card {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    border-radius: 10px;
    padding: 2rem 2rem;

    display: flex;
    justify-content: center;
    z-index: 1;
}

.media-card-left, .media-card-right {
    margin: auto;
    
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0px 20px;
    border-radius: var(--border-radius-px);
    color: white;
}

.media-card-left {
    width: 35%;
    height: 100%;
    max-width: 35%;
    max-height: 100%;
}

.media-card-right {
    width: 65%;
    height: 100%;
    max-width: 65%;
    max-height: 100%;
}

.media-card-right img.media-card-img {
    width: 100%;
    height: 90%;
    max-width: 100%;
    max-height: 90%;
    object-fit: cover;
    border-radius: 10px;
}

.media-card-left {
     /* align-items: center; */
     padding-right: 1em;
     display: flex;
     flex-direction: column;
}

.media-card-header {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}

.media-card-header i {
    padding-right: 15px;
}

div[class*="media-card-"] p a {
    text-decoration: solid;
    color: rgba(255,168,210,255);
    text-decoration: underline;
}

button.media-card-btn {
    max-width: 150px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    color: var(--flower-pink-text);
    background-color: var(--flower-pink);
}

button.media-card-btn:hover {
    background-color: var(--flower-pink-text);
    color: var(--flower-pink);
    transition: ease-in-out 0.2s;
}

.media-carousel-navigator {
    width: 100%;
    position: fixed;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    display: flex;
    justify-content: space-between;
    padding: 100px, 15px;
}

#prev, #next{
    opacity: 0;
}

.media-card-container:hover #prev, .media-card-container:hover #next{
    opacity: 100%;
    transition: .25s;
}

.media-carousel-btn {
    border: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    padding: 8px 30px;
    margin: 5px;
    font-size: 14px;
    z-index: 1;
}

.media-carousel-btn:hover {
    color: rgba(255,168,210,255);
}

.media-carousel-btn:active {
    transform: scale(0.98);
}

.media-carousel-btn:focus {
    outline: 0;
}

.media-carousel-btn:disabled {
    cursor: not-allowed;
}

.media-sub-nav {
    padding-top: 25px;
}

.media-sub-nav i {
    padding: 10px 20px;
}

.progress-wrapper {
    font-family: 'Muli', sans-serif;
    text-align: center;
    position: absolute;
    top: 90%;
    left: 50%;

    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.progress-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    margin-bottom: 10px;
    max-width: 100%;
    width: 100px;
}

.progress-container::before {
    content: '';
    background-color: var(--line-border-empty);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.progress {
    background-color: var(--line-border-fill);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.unseen {
    display: none;
}

.circle {
    background-color: rgba(0, 0, 0, 0.555);
    border-radius: 50%;
    height: 9px;
    width: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4 ease;
}

.circle.active {
    background-color: var(--line-border-fill);
    transition: 0.1s ease;
}

/* ---------------------------------------------------------------- */
/* --------------------- Miscellaneous ------------------------- */
/* ---------------------------------------------------------------- */

span#about-icon:hover {
    cursor: pointer;
    color: var(--home-page-theme-color);
}

/* ---------------------------------------------------------------- */
/* --------------------- Projects Preview ------------------------- */
/* ---------------------------------------------------------------- */
/*             Also in DiplayComponents.Card (mini-card)            */

div#projects-preview-view-more {
    width: 250px;
    height: 250px;
    margin: 1em 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}
 
/* ---------------------------------------------------------------- */
/* ------------------------- Animations --------------------------- */
/* ---------------------------------------------------------------- */

@keyframes blink {
    0%{
       opacity: 0; 
    }
    100%{
        opacity: 1;
    }  
}

/* ---------------------------------------------------------------- */
/* ------------------- Width Resolution Change -------------------- */
/* ---------------------------------------------------------------- */

@media only screen and (min-width: 4000px) {
    p#classic-hero-container-description {
        width: auto;
    }
}

@media only screen and (max-width: 1920px) {
    div.classic-hero-container {
        width: auto;
        top: 14vh;
        overflow-wrap: break-word;
        background-color: rgb(0 0 0 / 0.3);
        padding: 50px;
        backdrop-filter: blur(15px);
    }

    .type-container {
        font-size: 74.5px;
    }

    hr#classic-hero-container-divider {
        width: 100%; 
        border: 1px solid var(--home-page-theme-color);
    }
    
    p#classic-hero-container-description {
        color: white;
        font-size: 18px;
    }
    
    button#classic-hero-container-btn {
        font-size: 18px;
    }

    #classic-hero-container-badges {
        display: grid;
        justify-content:center;
        grid-template-rows: auto;
        grid-template-areas: 
        ". .";
    }
    
    .info-badge {
        max-width: 250px;
        width: 250px;
    }
    .info-badge:nth-of-type(1), .info-badge:nth-of-type(3) {
        margin-right: 2em;
    }

    div.info-badge-header {
        color: #a3daff;
        text-shadow: 0 0 1px black;

    }
    
    div.info-badge-header > span {
        font-size: 16px;
    }
    
    div.info-badge span.info-badge-text p, div.info-badge span.info-badge-text p * {
        font-size: 14px;
        color: white !important;
    }

    .type-cursor {
        border-right: 0.2rem solid var(--home-page-theme-color);
    }

    .type-container span:nth-of-type(1) {
        color: white;
        font-size: 60px;
    }

    .type-container span:nth-of-type(2) {
        /* text-shadow: 0 0 1px black; */
        color: var(--home-page-theme-color) !important;
    }

    .type-container span:nth-of-type(2), span.type-cursor {
        font-weight: 400;
        font-size: 70px;
    }
}

@media only screen and (max-width: 1400px) {
    div.classic-hero-container {
        top: 50vh;
    }

    div#classic-hero-container-badges, hr#classic-hero-container-divider { 
        display: none;
    }

    img#home-background-img {
        left: 0;
        object-position: 40% 0;
        -webkit-mask-image: none;
        mask-image: none;
    }

    .type-container span:nth-of-type(1) {
        /* font-weight: 500; */
        font-size: 60px;
    }
}

@media only screen and (max-width: 1200px) {
    .home-container { 
        padding: 0px 50px;
    }

    .media-card-container {
        width: 90%;
        height: 78%;
        top: 52%;
        box-shadow: 0 0 0 4pt rgba(0, 0, 0, 0.301);
    }
    
    .media-card {    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #prev, #next {
        opacity: 100%;
    }
    
    .media-card-right, .media-card-left {
        width: 95%;
        max-width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .media-card-right {
        height: 65%;
        max-height: 65%;
    }

    .media-card-left {
        font-size: smaller;
        padding-left: auto;
        height: 30%;
        max-height: 30%;
    }

    .media-card-right, .media-card-left {
        padding: 0px 0px;
    }
    
    .media-card-left p {
        align-self: center;
        max-width: 300px;
    }


    .media-card-right {
        text-align: center;
        padding-left: 0;
        align-content: center;
    }
    
    .media-card-right img {
        text-align: center;
    }

    .progress-wrapper {
        top: 95%;
    }

    .circle.active {
        background-color: #dfdfdf;
    }

}

@media only screen and (max-width: 1000px) {
    .projects-preview {
        grid-template-areas: 
        ". .";
    }
}

@media only screen and (max-width: 960px) {
    .type-container {
        font-size: 2rem;
    }

    img#home-background-img {
        left: 0;
        object-position: 50% 0;
    }

    div.classic-hero-container {
        margin: 0;
        height: auto;
        overflow-wrap: break-word;
        background-color: rgb(0 0 0 / 0.3);
        backdrop-filter: blur(15px);
        width: 100vw;
    }
    .type-container span:nth-of-type(1) {
        color: white;
        font-weight: 400;
        font-size: 50px;
    }
    
}

@media only screen and (max-width: 640px) {
    .projects-preview {
        grid-template-areas: 
        ".";
    }

    div#projects-preview-view-more {
        height: 100px;
    }
    p#classic-hero-container-description {
        max-width: 400px;
    }

    .type-container span:nth-of-type(1) {
        font-size: 2.5rem;
    }

    .type-container span:nth-of-type(2), span.type-cursor {
        font-weight: 400;
        font-size: 2.75rem;
    }

    p#classic-hero-container-description {
        width: auto;
        max-width: 300px;
        font-size: 1rem;
    }

    .card-container-mini:nth-of-type(3),
    .card-container-mini:nth-of-type(4), 
    .card-container-mini:nth-of-type(5) {
        display: none;
    }

    .home-container { 
        padding: 0px 5px;
    }
}

@media only screen and (max-width: 600px) {
    .media-sub-nav {
        display: none;
    }

    .media-card {
        flex-direction: column-reverse;
    }

    .card-container-mini:nth-of-type(2) {
        display: none;
    }
}

/* ---------------------------------------------------------------- */
/* ------------------ Height Resolution Change -------------------- */
/* ---------------------------------------------------------------- */

@media only screen and (max-width: 960px) and (max-height: 1250px) {
    .media-sub-nav {
        display: none;
    }

    .media-card {
        /* display: flex; */
        flex-direction: column-reverse;
    }

    .card-container-mini:nth-of-type(2),
    .card-container-mini:nth-of-type(3),
    .card-container-mini:nth-of-type(4), 
    .card-container-mini:nth-of-type(5) {
        display: none;
    }
}