.header {
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    flex-direction: column;
}

.gradient-back-about {
    width: 100vw;
    height: 600px;
    background: linear-gradient(-45deg,#EE7752, #E73C7E);
    z-index: 0;
}

p {
    text-align: justify;
}

.profile-container {
    width: 80vw;
    max-width: var(--maximum-allowable-screen-width-larger);
    min-width: 360px;
    height: 84vh;
    max-height: 950px;

    top: 100px;
    position: absolute;

    background-color: white;
    border-radius: 10px;
    padding: 1em 1em;
    z-index: 0;
}

.profile {
    width: 100%;
    height: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.profile-image, .profile-info {
    height: 100%;
}

.profile-image {
    width: 60%;
    height: 100%;
    padding-right: 1em;
    z-index: 3;
}

.profile-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    object-position: 50% 0;
}

.profile-info {
    width: 40%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#last-name {
    padding-top: 0;
}

.profile-info p {
    text-align: center;
}

.profile-info ul li, .profile-info ul li i {
    color: black;
}

.profile-info ul {
    list-style: none;
}

.profile-info ul li:hover, .profile-info ul li:hover i {
    cursor: pointer;
    transition: all 0.4s ease-in-out 0s;
}

#ln:hover i {
    color: #2867B2;
}
#tw:hover i {
    color: #1DA1F2
}
#ig:hover i {
    color: #C13584;
}
#gt:hover i {
    color: #6CC644;
}
#em:hover a, #em:hover i {
    color: coral;
    transition: 0.25s ease-out;
}

.a-contact {
    padding-top: 20px;
    display: flex;
    justify-content: space-evenly;
}

.a-contact i {
    padding-right: 10px;
}

.a-contact a {
    color: black;
}

.s-contact {
    width: 100%;
    height: auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-evenly;
}

.profile-info h3, .profile-info h1{
    padding-top: 15px;
}

.profile-info p {
    font-size: 15px;
    color: rgb(85, 85, 85);
    padding: 0 25px;
}

.spacer {
    width: 100%;
    height: calc(100vh - 600px);
}

.profile-shadow {
    width: 80vw;
    max-width: var(--maximum-allowable-screen-width-larger);
    height: 84vh;
    max-height: 950px;

    top: 100px;
    position: absolute;

    border-radius: 0px 0px 10px 10px;
    box-shadow: 0 15px 15px 2px rgba(0, 0, 0, 0.4);

    z-index: -1;
}

/* ---------------------------------------------------------------- */
/* ----------------------------- Footer --------------------------- */
/* ---------------------------------------------------------------- */

.about-footer {
    position: inherit;
    width: 100%;
    height: auto;
    padding: 20px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
}

.ft-text {
    width: 90%;
    max-width: var(--maximum-allowable-screen-width-larger);
    padding: 20px 20px;
    border-radius: var(--border-radius-px);
    box-shadow: 0 0 0 2pt rgba(0, 0, 0, 0.342);
}

.ft-text h3 {
    margin-bottom: 10px;
}

.ft-text p a {
    color: rgb(21, 100, 173);
    text-decoration: underline;
}

/* ---------------------------------------------------------------- */
/* ----------------- Other Screen Resolutions --------------------- */
/* ---------------------------------------------------------------- */

@media only screen and (max-width: 900px) {

    .cover-image {
        background-position-y: 0px;
    }

    .section2{
        flex-direction: column;
        align-items: center;
    }

    .s2-img, .s2-text {
        width: 90%;
    }

    .profile {
        flex-direction: column;
    }

    .profile-image, .profile-info {
        width: 100%;
    }
    
    .profile-image {
        height: 40%;
        padding-right: 0;
    }

    .profile-image img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
    
    .profile-info {
        height: 60%;
        padding: 15px 10px;
        display: flex;
    }

    .intro {
        padding-top: 5px;
    }

    #first-name {
        padding-top: 0;
        padding-right: 10px;
    }

    .intro {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 600px) {
    .intro {
        padding-bottom: 10px;
    }
    .profile-info {
        font-size: 0.85rem;
    }
    .profile-info p {
        font-size: 0.85rem;
    }
    .profile-info .a-contact a {
        font-size: 0.85rem;
    }
}