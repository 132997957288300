/* ---------------------------------------------------------------- */
/* ------------------------ Title Component ----------------------- */
/* ---------------------------------------------------------------- */
.title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em auto;
}

.title-container h1, .title-container h2 {
    text-align: center;
    padding: 50px 0;
}

.title-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em auto;
}

img[id*="title-image"] {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-height: 350px;
    max-height: 750px;
    object-fit: cover;
    border-radius: 2px;
}

/* ---------------------------------------------------------------- */
/* ------------------------ Text Component ------------------------ */
/* ---------------------------------------------------------------- */
.text-container {
    width: 100%;
    display: flex;
    margin: 1em 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-container span {
    width: 100%;
}

.text-container span hr {
    border: 1px solid black;
    margin-bottom: 15px;
}

.text-container p.indent {
    text-indent: 50px;
}

.text-container p {
    width: 100%;
    padding: 0px 20px;
}

.text-container p a {
    color: coral;
}

.text-container p ol, .text-container p ul {
    text-indent: 10px;
}

/* ---------------------------------------------------------------- */
/* ----------------------- Image Component ------------------------ */
/* ---------------------------------------------------------------- */
.image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em auto;
}

img[id*="image-"] {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 2px;
}

.small-image {
    width: 40%;
}

.medium-image {
    width: 65%;
}

@media only screen and (max-width: 960px) {
    .small-image, .medium-image  {
        width: 100%;
    }
}

/* ---------------------------------------------------------------- */
/* ----------------------- Video Component ------------------------ */
/* ---------------------------------------------------------------- */
.video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em auto;
}

video[id*="video-"] {
    width: 100%;
    height: 80%;
    object-fit: cover;
}

/* ---------------------------------------------------------------- */
/* ------------------- Notes & Bullets Component ------------------ */
/* ---------------------------------------------------------------- */
.notes-container {
    width: 100%;
}

.text-container.notes-text {
    margin-bottom: 0px;
}

hr.solid {
    border-top: 3px solid #bbb;
}

.note-spacer {
    width: 100%;
    height: 100px;
}

.bullet-container {
    width: 100%;
    display: flex;
    float: left;
    margin: 20px 0px;
}

.bullet-container ol,
.bullet-container ul {
    line-height: 180%;
    margin-left: 10px;
    list-style-position: inside;
}

.bullet-container ul>li, 
.bullet-container ol>li  {
    padding: 2.5px 0px;
    color: black;
    font-weight: 575;
}

.bullet-container ol {
    counter-reset: item;
}
   
/* .bullet-container ol>li:before {
    display: inline-block;
    width: 1.5em;
    padding-right: 0.5em;
    font-weight: bold;
    text-align: right;
} */

/* ---------------------------------------------------------------- */
/* ----------------------- Tooltip Component ---------------------- */
/* ---------------------------------------------------------------- */
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    display: none;
    background-color: black;
    color: #fff;
    font-size: small;
    text-align: center;
    padding: 5px 5px;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    display: inherit;
    /* position: fixed; */
    white-space: nowrap;
    position: absolute;
    top: 0px;
    left: 5px;
}

.tip-bottom .tooltiptext {
    margin-left: -42px;
    margin-top: 30px;
}
.tip-bottom .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.tip-top .tooltiptext {
    margin-left: -42px;
    margin-top: -40px;
}
.tip-top .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

/* ---------------------------------------------------------------- */
/* ------------------------ Code Component ------------------------ */
/* ---------------------------------------------------------------- */
.code-container {
    width: auto;
    height: auto;
    position: relative;
    margin: 1em auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.type-gist {
    height: 100%;
    /* width: 75%; */
    min-height: 200px;
    min-width: 350px;
}

.type-gist iframe {
    /* height: 100%; */
    min-height: 200px;
}

pre[class*="preblock-"] {
    width: 100%;
    height: auto;
    padding: 1em 2em;
    border-radius: var(--border-radius-px-rounder);
    font-size: 80%;
    line-height: 1.2em;
    overflow-x: auto;
    overflow-y: auto;
    /* max-height: 300px; */
}

.type-highlight pre {
    width: 100%;
    height: auto;
}

pre[class*="preblock-"]#white {
    border: 1px solid #444;
    background-color: #f7f7f7;
}

pre[class*="preblock-"]#black {
    border: 1px solid #99E293;
    background-color: #111;
}

pre[class*="preblock-"] code {
    white-space: pre;
}

pre[class*="preblock-"] code#black {
    color: #99E293;
}

pre[class*="preblock-"] button:hover, #btn-highlighted {
    background-color: rgba(150, 150, 150, 0.2);
}

.btn-copy {
    color: black;
    position: absolute;
    right: 0.5em;
    background-color: transparent;
    background-repeat: no-repeat;
    border-radius: var(--border-radius-px);
    border: 1px solid #444;
    cursor: pointer;
    outline: none;
    padding: 2px 4px;
}

button#btn-colored-black {
    border: 1px solid #99E293;
    color: #99E293;
}

button[id*="btn-colored"] {
    top: 0.5em;
}

.btn-highlighted {
    top: 1em;
}

#icon-code-black {
    color: #99E293;
}

#icon-code-white {
    color: black;
}

code[class*="language-"] span {
    font-size: smaller;
}

pre[class="pre-undefined"] {
    border: 1px solid #840000;
    background-color: #ff8888;
    padding: 5px 5px;
    border-radius: var(--border-radius-px);
}

code[class="code-undefined"] {
    color: #840000;
    font-size: smaller;
}

@media only screen and (max-width: 600px) {
    .code-container {
        max-width: 300px;
    }
}

@media only screen and (max-width: 450px) {
    .type-gist {
        min-width: auto;
    }
}

/* ---------------------------------------------------------------- */
/* --------------------- Left Right Component --------------------- */
/* ---------------------------------------------------------------- */
.left-right-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-content: center;
}

.lr-true-spacing {
    justify-content: center;
}

.right, .left {
    width: 48%;
    height: auto;
    max-width: 48%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shrinked-right, .shrinked-left {
    width: auto;
    max-width: 32%;
    height: auto;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enlarged-right, .enlarged-left {
    width: auto;
    max-width: 64%;
    height: auto;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1200px) {
    .left-right-container {
        flex-direction: column;
    }

    .right, .left,
    .shrinked-right, .shrinked-left,
    .enlarged-right, .enlarged-left {
        width: 100%;
        max-width: 100%;
    }
}

/* ---------------------------------------------------------------- */
/* --------------------- Top Bottom Component --------------------- */
/* ---------------------------------------------------------------- */
.dual-code-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
}

.top, .bottom {
    width: auto;
    height: 48%;
    max-width: 100%;
    max-height: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ---------------------------------------------------------------- */
/* ----------------------- Footer Component ----------------------- */
/* ---------------------------------------------------------------- */
footer.basic-footer {
    width: 100%;
    height: 200px;
}

/* ---------------------------------------------------------------- */
/* ---------------------- P5 Canvas Component --------------------- */
/* ---------------------------------------------------------------- */
.p5-container {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.canvas-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
    margin: 1em 1em;
}

.canvas-container canvas {
	border:  10px solid white;
	border-radius: var(--border-radius-px);
	box-shadow: 0 15px 15px 2px rgba(100, 100, 100, 0.5);
}

@media only screen and (max-width: 1200px) {
    .p5-container {
        flex-direction: column;
    }
}

/* ---------------------------------------------------------------- */
/* -------------------- EmbeddedSite Component -------------------- */
/* ---------------------------------------------------------------- */
.iframe-container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    margin: 1em 0px;
}

.iframe-container iframe {
    width: 100%;
    height: 600px;
}

/* ---------------------------------------------------------------- */
/* ----------------------------- Label ---------------------------- */
/* ---------------------------------------------------------------- */
.label {
    width: auto;
    padding: 2.5px 5px;
    border-radius: 2px;
    margin: 2px;
}

/* ---------------------------------------------------------------- */
/* ------------------------- Card Component ----------------------- */
/* ---------------------------------------------------------------- */
.card-content {
    position: absolute;
    width: 100%;
    max-width: var(--maximum-allowable-screen-width);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

button#card-content-back-btn {
    left: 50px !important;
    bottom: 50px !important;
    position: fixed !important;
    border: none;
    border-radius: 2px;
    width: 100px;
    height: 50px;
    cursor: pointer;
    color: black;
    background-color: #fdef73;
    z-index: 50;
}

button#card-content-back-btn:hover {
    color: #fff;
    cursor: pointer;
    background-color: #d6ca45;
    transition: 0.25s ease-in-out;
}

@media only screen and (max-width: 1200px) {
    #card-content-back-btn {
        display: none;
    }
}

.card-container {
    height: 450px;
}

.card-container-mini {
    height: 250px;
}

.card-container, .card-container-mini {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 1em 0.5em;
    border-radius: 8px;
    background-color: white;
	box-shadow: 0 15px 15px 2px rgba(100, 100, 100, 0.4);
    cursor: pointer;
    /* border: solid 1px black; */
}

.card-container:hover, .card-container-mini:hover {
    transition: 0.25s ease-in-out;
	box-shadow: 0 15px 15px 5px rgba(50, 50, 50, 0.5);
}

.card-image, .card-image-mini,
.card-description, .card-description-mini {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
}

.card-image {
    height: 34%;
    max-height: 34%;
}

.card-image-mini {
    height: 50%;
    max-height: 50%;
}

.card-image img, .card-image-mini img {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.card-description {
    height: 66%;
    max-height: 66%;
}

.card-description-mini {
    height: 50%;
    max-height: 50%;
}

.card-description, .card-description-mini {
    flex-direction: column;
    font-size: smaller;
    padding: 20px 15px;
    overflow-y: auto;
    scrollbar-width: none;
}

.card-description > div {
    padding: 5px 0px;
}

hr.card-divider {
    width: 100%;
    margin: 5px 0px;
}

.card-segment {
    width: 100%;
}

.card-description-labels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#card-description-text > h3#card-title {
    font-weight: var(--font-bold);
    font-size: 16.25px;
    padding-bottom: 10px;
}

#card-description-text > p {
    text-align: start;
    /* text-indent: 1em; */
}

/* ---------------------------------------------------------------- */
/* ----------- ImageContentPlaceholder Component ------------------ */
/* ---------------------------------------------------------------- */

.image-content-placeholder {
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    border-radius: var(--border-radius-px);
}

.shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #b5b5b5;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}

/* ---------------------------------------------------------------- */
/* ----------------- LoadingPlaceholder Component ----------------- */
/* ---------------------------------------------------------------- */
.loading-wrapper-mini {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: #eee;
    border-radius: var(--border-radius-px);
}

.loading-wrapper-mini h3 {
    margin: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading-wrapper-mini h3 i {
    margin-bottom: 10px;
}

.loading-wrapper-minis.loading-style-small {
    width: 150px;
    height: 150px;
}

.loading-wrapper-mini.loading-style-medium {
    width: 300px;
    height: 300px;
}

.loading-wrapper-mini.loading-style-large {
    width: 450px;
    height: 450px;
}

@media only screen and (max-width: 600px) {
    .loading-wrapper-mini.loading-style-large {
        width: 300px !important;
        height: 300px !important;
    }
}

/* ---------------------------------------------------------------- */
/* -------------------- GradientBack Component -------------------- */
/* ---------------------------------------------------------------- */
.gradient-back-generic {
    width: 100vw;
    height: 600px;
    z-index: -5;

    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -70px);
}

.gradient-back-generic.mini {
    height: 70px;
}

/* ---------------------------------------------------------------- */
/* ------------------- Research Title Paper ----------------------- */
/* ---------------------------------------------------------------- */
.research-paper-title-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.research-paper-title-section-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.research-paper-title-section-2 {
    display: flex;
    padding: 15px;
    border-radius: var(--border-radius-px-rounder);
    border: 2px solid black;
    background-color: #eee;
}

.research-paper-link {
    font-size: 1em;
    text-decoration: underline;
    color: blue;
    padding: 5px 8px;
    border-radius: var(--border-radius-px);
}

.research-paper-link:hover {
    background-color: #eee;
}

.research-paper-title-section-2 div.card-container-mini {
    margin-top: 0px;
}

.research-paper-authors {
    display: flex;
    padding: 10px 0;
}

.research-paper-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.research-paper-title-section-1 .label {
    display: inline-block;
    margin: 5px;
}

div.research-paper-title-image {
    max-width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em auto;
}

div.research-paper-title-image img {
    position: relative;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 2px;

    border-top-left-radius: var(--border-radius-px-rounder);
    border-bottom-left-radius: var(--border-radius-px-rounder);
}

span.research-title-abstract {
    padding: 0 20px;
}

span.research-title-abstract p {
    text-align: left;
}

hr#research-paper-title-hr {
    width: 100%;
    margin: 10px 0px;
    border: 1px solid black;
}

@media only screen and (max-width: 1400px) {
    .research-paper-title-section-2 .research-paper-title-image {
        display: none;
    }
}

@media only screen and (max-width: 1100px) {
    div.research-paper-title-image {
        max-width: 100%;
    }

    .research-paper-title-section-2 .research-paper-title-image {
        display: inline-block;
    }

    .research-paper-title-section-2 .research-paper-title-image img {
        border-radius: var(--border-radius-px-rounder);
    }

    .research-paper-title-section-2 .related-project {
        padding-top: 25px;
    }

    .research-paper-title-section-2 {
        flex-direction: column;
        align-items: center;
    }
}

/* @media only screen and (max-width: 640px) {
} */

/* ---------------------------------------------------------------- */
/* ----------------------- Math Equation -------------------------- */
/* ---------------------------------------------------------------- */
span.math-equation {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 2em;
    margin: 20px 0px;
}

@media only screen and (max-width: 960px) {
    span.math-equation {
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 640px) {
    span.math-equation {
        font-size: 0.8em;
    }
}

/* ---------------------------------------------------------------- */
/* ----------------------- ComplexSection ------------------------- */
/* ---------------------------------------------------------------- */
div.complex-section {
    width: 100%;
    height: 100%;
}

div.complex-subsection {
    padding-left: 50px;
}

@media only screen and (max-width: 640px) {
    div.complex-subsection {
        padding-left: 0px;
    }
}