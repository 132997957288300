.photography-wrapper {
    top: 70px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Gallery Select */
.gallery-grid {
    width: auto;
    height: auto;
    padding: 15px 0px;
    margin: 15px auto;
    background: #F0F7E0;
    border-radius: var(--border-radius-px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gallery-grid>hr {
    width: 90%;
    border: 0.5px solid #95BA61;
}

#select {
    color: #95BA61;
    padding-bottom: 10px;
    font-weight: var(--font-bold);
}

.gal-select {
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gal-select li {
    color: #613659;
    width: auto;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: var(--font-bold);
    margin: 0px 1em;
    padding: 10px 15px;
}

.gal-select li:hover {
    cursor: pointer;
    color: #613659;
    font-weight: var(--font-bolder);
    transition: 0.25s;
    border-radius: var(--border-radius-px);
    background-color: #D3B1C2;
}

.gal {
    color: black;
}
 
.gal-select>li.gal-active {
    color: #613659;
    font-weight: var(--font-bolder);
    transition: 0.2s;
    background-color: #D3B1C2;
    border-radius: 3px;
}

.gallery-container {
    margin: 0px auto;
    columns: 4;
    column-gap: 10px;
    width: 80vw;
    max-width: var(--maximum-allowable-screen-width-larger);
}

/* ---------------------------------------------------------------- */
/* ------------------------ Gallery Photo ------------------------- */
/* ---------------------------------------------------------------- */
.gallery-photo-card {
    position: relative;
    width: auto;
    max-width: 450px;
    min-width: 300px;
    height: auto;
    max-height: 550px;
    min-height: auto;
    margin: 0.5em auto;

    break-inside: avoid;
}

.gallery-photo-card:hover {
    cursor: pointer;
}

img.gallery-photo-image {
    width: 100%;
    height: 100%;
    max-width: auto;
    max-height: 550px;
    object-fit: cover;
    border-radius: var(--border-radius-px);
}

.gallery-photo-cover {
    display: none;
}

.gallery-photo-cover i {
    color: white;
}

.gallery-photo-card:hover span.gallery-photo-cover{
    display: inherit;
    width: 100%;
    height: calc(100% - 4px);

    top: 0px;
    left: 0px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-px);

    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

/* NOTE: .modal is also present in artwork.css */
.photography-modal {
    width: 100vw;
    height: 100vh;
    z-index: 15;
}

@media only screen and (max-width: 1500px) {
    .gallery-container {
        columns: 3;
    }
}

@media only screen and (max-width: 1150px) {
    .gallery-container {
        columns: 2;
    }
}

@media only screen and (max-width: 750px) {
    .gallery-container {
        columns: 1;
    }
}

@media only screen and (max-width: 600px) {
    .gallery-grid {
        width: 80vw;
    }

    .gal-select {
        flex-direction: column;
    }

    .gal-select li {
        margin: 15px 0px;
    }

    .gal-select>li.gal-active {
        width: 80%;
        padding: 10px 15px;
    }
}
